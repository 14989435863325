<template>
  <div class="index">
    <van-nav-bar
      title="劳务协议"
      fixed
      left-arrow
      @click-left="$router.back()"
    />
    <div class="head"><img src="@/assets/image/my/组 634@2x.png" /></div>

    <div class="index-box">
      <div class="index-box-renzheng">
        <img src="../../../assets/image/my/renzheng.png" />
        <div @click.stop="download" class="index-box-renzheng-btn"></div>
      </div>
    </div>

    <div class="index-content">
      <el-button
        style="background: #ef5840; border: 1px solid #ef5840"
        icon="el-icon-upload2"
        type="success"
        @click="uploadClick"
        >上传协议</el-button
      >

      <ul class="content-ul">
        <div v-if="list.length == 0" class="zanwu">
          <van-empty description="暂无数据" />
        </div>
        <div v-else>
          <li class="content-li" v-for="(item, index) in list" :key="index">
            <div class="content-li-bgc">
              <img src="../../../assets/image/myAgreement/heng.png" />
            </div>
            <div class="content-li-head">
              <div class="content-li-xieyi">
                <img src="../../../assets/image/myAgreement/xieyi.png" />
              </div>
              <div class="content-li-name">{{ item.name }}</div>
              <div
                class="content-li-text"
                v-if="item.audit_status_text == '待审核'"
              >
                （待审核）
              </div>
              <div
                v-if="item.audit_status_text == '审核驳回'"
                class="content-li-text avtive-hong"
              >
                （审核驳回）
              </div>
              <div
                v-if="item.audit_status_text == '审核通过'"
                class="content-li-text avtive-lv"
              >
                （审核通过）
              </div>
            </div>

            <div class="content-li-time">
              <div>上传时间：</div>
              <div>{{ item.created_at }}</div>
            </div>

            <ul class="content-li-bot">
              <li class="content-li-bot-item" @click="vie(item.url)">
                <div class="content-li-bot-item-img1">
                  <img src="../../../assets/image/myAgreement/chakan.png" />
                </div>
                <div class="content-li-bot-item-name">查看协议</div>
              </li>
              <li
                class="content-li-bot-item content-li-bot-item-active"
                @click="del(item.id)"
                v-if="item.audit_status_text != '审核通过'"
              >
                <div class="content-li-bot-item-img2">
                  <img src="../../../assets/image/myAgreement/shanchu.png" />
                </div>
                <div class="content-li-bot-item-name">删除协议</div>
              </li>
              <li
                class="content-li-bot-item"
                @click="reject(item.audit_remarks)"
                v-if="item.audit_status_text == '审核驳回'"
              >
                <div class="content-li-bot-item-img3">
                  <img src="../../../assets/image/myAgreement/fankui.png" />
                </div>
                <div class="content-li-bot-item-name">驳回意见</div>
              </li>
            </ul>
          </li>
        </div>
      </ul>
    </div>

    <!-- 上传协议 -->
    <div class="upload-protocol">
      <el-dialog title="上传协议" :visible.sync="protocolShow" width="90%">
        <!-- 内容 -->
        <div class="upload-protocol-contents">
          <div style="display: flex; align-items: center; margin: 5px 0 12px 0">
            <div class="must">*</div>
            <div>上传文件</div>
          </div>

          <div class="uploads-box">
            <!-- <el-upload
              :before-upload="wenBeforeUploadbbbbbbbbbbbbbbbbbb"
              class="upload-demo"
              :action="action"
              multiple
              :file-list="fileList"
              :on-success="accessorySuccess"
              :data="uploadData1"
              ref="accessory"
              v-model="enclosure"
              :limit="1"
              :before-remove="beforeremove"
            >
              <el-button
                size="small"
                type="info"
              >选择文件</el-button>
              <div class="box-border"></div>
            </el-upload>
            <span class="ticks">允许上传PDF和照片类型文件</span> -->
            <van-uploader
              v-model="fileList1"
              :max-count="1"
              :after-read="afterRead1"
              accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,.xls,.jpg,.png,.gif,.webp,.jpeg,.psd"
              @delete="deleteImg"
            />
          </div>

          <div style="margin: 50px 0 12px 0" v-if="agreementName">
            {{ agreementName }}
          </div>
        </div>
        <!-- 内容 -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="protocolShow = false">取 消</el-button>
          <el-button
            type="primary"
            @click="uploadProtocolCreate"
            v-prevent="2000"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
    <!-- 上传协议 -->
  </div>
</template>

<script>
export default {
  name: "myAgreement",
  data() {
    return {
      list: [],
      protocolShow: false, //上传协议弹窗
      action: `https://up-z0.qiniup.com`,
      enclosure: "", //附件
      file_name: "",
      fileList: [],
      fileList1: [],
      agreementName: "",
      //发票
      invoicefileList: [],
      uploadData1: { key: "", token: "" },
    };
  },
  created() {
    this.getAuthContract();
  },
  methods: {
    //上传协议
    async afterRead1(file) {
      let time = Math.round(Math.random() * 80 + 20);
      let imgName = file.file.name.slice(file.file.name.lastIndexOf(".") + 1);
      //1111111111111111111111111111111111111111111
      file.status = "uploading";
      file.message = "上传中...";
      const { data: data } = await this.$http.get("system/qiniuToken");
      let formData = new FormData();
      formData.append("token", data.data.token);
      formData.append("file", file.file);
      formData.append("key", new Date().getTime() + time + "." + imgName); //file.file.name   Math.round(Math.random() * 80 + 20)) +
      this.$http.post("https://up-z0.qiniup.com/", formData).catch((err) => {
        console.log(err);
        this.$toast("上传成功");
        this.enclosure = `https://resources.wsg.medcase.com.cn/${err.key}`;
        this.file_name = err.key;
        file.status = "done";
      });
      // 此时可以自行将文件上传至服务器
      // let formData = new FormData();
      // formData.append("file", file.file);
      // const { data: data } = await this.$http.post('/system/upload', formData)
      // this.form.id_card1 = data.data.url
    },
    //删除协议
    deleteImg(e) {
      // console.log(e);
      this.fileList1 = [];
      this.enclosure = "";
      this.file_name = "";
    },
    //1111111111111111111111111111111111111111111111111111111
    //列表
    async getAuthContract() {
      const { data: data } = await this.$http.get("auth/contract", {
        params: {
          page: 1,
          limit: 100,
        },
      });
      this.list = data.data.list;
    },
    //下载
    async download() {
      let system = navigator.userAgent;
      let isAndroid =
        system.indexOf("Android") > -1 || system.indexOf("Adr") > -1; // android终端
      let isiOS = !!system.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      const { data: data } = await this.$http.get("auth/download/contract", {
        params: {
          id: 1,
        },
      });
      if (isAndroid) {
        //android终端
        window.open(data.data.url);
      } else if (isiOS) {
        //ios终端
        location.href = data.data.url;
      }
    },
    //查看
    vie(url) {
      window.open(url);
    },
    //删除
    del(id) {
      this.$dialog
        .confirm({
          title: "确定要删除该条协议吗",
        })
        .then(async () => {
          const { data: data } = await this.$http.get("auth/delete/contract", {
            params: { id },
          });
          this.$toast(data.message);
          this.getAuthContract();
        })
        .catch(() => {});
    },
    //驳回意见
    reject(name) {
      this.$dialog.alert({
        title: "驳回意见",
        message: name,
      });
    },
    //打开上传协议
    uploadClick(item) {
      this.protocolShow = true;
      this.fileList = [];
      this.fileList1 = [];
      this.agreementName = "";
      this.enclosure = "";
      this.file_name = "";
    },
    //发票上传前
    async wenBeforeUploadbbbbbbbbbbbbbbbbbb(file) {
      const is50M = file.size / 1024 / 1024 < 50;
      if (!is50M) {
        this.$message.error("上传图片大小不能超过 50MB!");
        return false;
      }
      //获取七牛云的token
      const { data: data } = await this.$http.get("system/qiniuToken");
      this.uploadData1.token = data.data.token;
      this.uploadData1.key = file.uid + file.name;
    },
    //上传协议接口
    accessorySuccess(res, file, fileList) {
      if (res.key != "" || res.key != undefined || res.key != null) {
        this.enclosure += `https://resources.wsg.medcase.com.cn/${res.key}`;
        this.file_name = res.key;
        this.$message({
          message: "文件上传成功",
          type: "success",
        });
      } else {
        this.$message.error("上传失败");
      }

      console.log(this.file_name);
    },
    //删除附件
    beforeremove(res, file, fileList) {
      this.fileList = [];
      this.agreementName = "";
    },
    //上传协议 确定
    async uploadProtocolCreate() {
      console.log(this.file_name);
      const { data: data } = await this.$http.post("auth/upload/contract", {
        file_name: this.file_name,
        url: this.enclosure,
      });
      this.$message({
        message: data.message,
        type: "success",
      });
      // alert(data.data.message)
      this.getAuthContract();
      this.protocolShow = false;
    },
  },
};
</script>
<style lang="less" scoped>
.index {
  position: relative;
  /deep/.van-cell::after {
    border: 0 !important;
  }
  /deep/ .el-dialog {
    z-index: 100 !important;
  }
  /deep/ .van-cell {
    padding: 0 !important;
  }
  .head {
    width: 108%;
    position: absolute;
    top: -20px;
    left: -4%;
  }

  .index-box-renzheng {
    position: relative;
    top: 15px;
    left: 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #e6ecee;
    .index-box-renzheng-btn {
      position: absolute;
      top: 145px;
      right: 100px;
      width: 58px;
      height: 14px;
      z-index: 9999;
      // background-color: pink;
    }
  }

  .index-content {
    position: relative;
    top: 35px;

    .content-ul {
      position: relative;
      margin-top: 24px;
      padding-bottom: 1px;
      .content-li {
        position: relative;
        box-shadow: 0px 3px 8px 1px #d7e9eb;
        border-radius: 11px;
        padding: 12px 16px;

        margin-bottom: 18px;

        background-color: #fff;
        .content-li-bgc {
          position: absolute;
          top: -7px;
          right: 16px;
          width: 113px;
          height: 6px;
        }
        .content-li-head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .content-li-xieyi {
            width: 14px;
            height: 14px;
            margin-top: 2px;
            margin-right: 4px;
          }
          .content-li-name {
            font-size: 14px;
            color: #000000;
            font-weight: 600;
            margin-left: 5px;
            // flex: 1;
            width: 190px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .content-li-text {
            font-size: 14px;
            margin-left: 10px;
            // padding-left: 20px;
            color: #e8a42f;
          }
          .avtive-hong {
            color: #ff0a0a;
          }
          .avtive-lv {
            color: #07c90d;
          }
        }
        .content-li-time {
          display: flex;
          align-items: center;
          padding: 22px 0;
          font-size: 14px;
          color: #4d4d4d;

          border-bottom: 1px solid #eaf2f2;
        }

        .content-li-bot {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 11px;
          .content-li-bot-item {
            display: flex;
            align-items: center;
            .content-li-bot-item-img1 {
              width: 17px;
              height: 13px;
            }
            .content-li-bot-item-img2 {
              width: 13px;
              height: 16px;
            }
            .content-li-bot-item-img3 {
              width: 16px;
              height: 15px;
            }
            .content-li-bot-item-name {
              margin-left: 5px;
              font-size: 14px;
              color: #333333;
            }
          }
          // .content-li-bot-item-active {
          //   padding: 0 20px;
          // }
        }
      }
    }
  }

  .upload-protocol .upload-protocol-contents .upload-protocol-contents-top {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }
  /* //附件
//新的 */
  .uploads-box {
    position: relative;
  }
  .upload-demo {
    align-items: center;
  }
  .upload-demo .el-button {
    border-radius: 6px 0 0 6px;
  }
  .ticks {
    position: absolute;
    color: #cc0808;
    font-size: 12px;
    margin-left: 3px;
    margin-top: 10px;
  }
  /* //文件位置 */
  ::v-deep .el-upload-list__item {
    position: absolute !important;
    top: -6px !important;
    left: 80px !important;
    width: 64% !important;
  }
  .box-border {
    position: absolute;
    top: 1px;
    left: 80px;
    /* // width: 421px; */
    width: 65%;
    height: 32px;
    border: 1px solid #f0f0f0;
    border-radius: 0 6px 6px 0;
  }

  /deep/ .van-empty {
    padding-top: 0 !important;
  }
}
</style>
