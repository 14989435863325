<template>
  <div class="login">
    <!-- <div class="login-bgc-top">
      <img src="@/assets/image/login/bgc-top-r.png" />
    </div> -->
    <div class="login-bgc"><img src="@/assets/image/login/bgc.png"></div>
    <div class="login-title">注册</div>
    <div class="login-logo"><img src="@/assets/image/login/logo.png"></div>

    <div class="FormSubmit" style="padding: 0 25px;">
      <van-form ref="form" @submit="onSubmit">
        <van-field class="iconfont icon-shoujihao1" v-model="tel" type="tel" clearable name="tel" :rules="userTel"
          placeholder="请输入手机号" style="margin-bottom: 15px;" />
        <van-field class="iconfont icon-shoujihao1" v-model="pass" type="password" clearable name="tel" :rules="mima"
          placeholder="请输入密码" style="margin-bottom: 15px;" />
        <van-field class="iconfont icon-shoujihao1" v-model="confirm_pwd" type="password" clearable name="tel"
          :rules="querenmima" placeholder="请再次输入密码" style="margin-bottom: 15px;" />
        <van-field v-model="sms" center ref="yanzhengCode" :rules="userMsg" clearable
          class="iconfont icon-yanzhengyanzhengma" placeholder="请输入手机验证码" type="digit" maxlength="6">
          <template #button>
            <van-button type="primary" size="small" class="getCode" @click="sendCode" :disabled="ifdisabled"
              native-type="button">
              {{ codeMsg }}
            </van-button>
          </template>
        </van-field>
        <div class="login-btn">
          <van-button block type="primary" native-type="submit"><span style="fontsize: px">注&nbsp;册</span>
          </van-button>
        </div>
        <div class="text-box">
          <div class="text-box-no">已有帐号，</div>
          <div class="text-box-go" @click="$router.push('login')">去登录</div>
        </div>
      </van-form>
    </div>

    <!-- <div class="login-bgc-bottom">
      <img src="@/assets/image/login/bgc-bottom.png">
    </div> -->
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      pass: "",
      confirm_pwd: "",
      tel: "",
      sms: "",
      identity: "1",
      ifdisabled: false,
      codeNum: 60,
      codeMsg: "获取验证码",
      count: "",
      userTel: [
        {
          required: true,
          message: "请输入手机号",
          trigger: "onBlur",
        },
        {
          validator: (value) => {
            return /^1[23456789]\d{9}$/.test(value);
          },
          message: "手机号格式错误，请检查！",
        },
      ],
      mima: [
        {
          required: true,
          message: "请输入密码",
          trigger: "onBlur",
        },
      ],
      querenmima: [
        {
          required: true,
          message: "请再次输入密码",
          trigger: "onBlur",
        },
        {
          validator: (value) => {
            return value == this.pass;
          },
          message: "两次密码不一致",
        },
      ],
      userMsg: [
        {
          required: true,
          message: "请输入验证码",
          trigger: "onBlur",
        },
        {
          validator: (value) => {
            // return /^\d{6}$/.test(value);
          },
          message: "验证码格式错误，请检查！",
        },
      ],
    };
  },
  methods: {
    // 获取短信验证码
    async sendCode() {
      // 验证手机号格式是否正确
      if (!this.tel) {
        this.$toast("请输入手机号！");
        return;
      }
      if (!this.userTel[1].validator(this.tel)) return;
      //发送请求
      const { data: data } = await this.$http.post("system/sms", {
        phone: this.tel,
        type: " REG",
      });
      if (data.code === 200) {
        this.$toast("验证码已发送！");
        // 禁用按钮
        this.ifdisabled = true;
        // 倒计时
        this.count = this.codeNum;
        let timer = setInterval(() => {
          if (this.count > 1 && this.count <= this.codeNum) {
            this.count--;
            this.codeMsg = `重新发送(${this.count})`;
          } else {
            // 判断什么时候停止定时器
            clearInterval(timer);
            this.codeMsg = "获取验证码";
            this.ifdisabled = false;
            this.count = "";
          }
        }, 1000);
      } else {
        this.$toast(data.message);
      }
    },
    //注册
    async onSubmit() {
      const { data: data } = await this.$http.post("auth/register", {
        userTypeId: "1",
        username: this.tel,
        password: this.pass,
        confirm_pwd: this.confirm_pwd,
        code: this.sms,
      });
      if (data.code == 200) {
        localStorage.setItem("token", data.data.access_token);
        this.getMeApi();
      }
    },
    //获取用户信息 判断是否认证
    //获取用户信息 判断是否认证
    async getMeApi() {
      const { data: data } = await this.$http.post("auth/me");
      localStorage.setItem("information", JSON.stringify(data.data));
      if (data.data.doctor_status == 0) {
        this.$router.push("certification");
      } else {
        this.$toast(data.message);
        this.$router.push("/homeTopic");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.login {
  height: 100vh;
  position: relative;
  overflow: hidden;

  .login-bgc {
    position: absolute;
    width: 100%;
    height: 564px;
    background: url(../../assets/image/login/bgc.png) no-repeat center;
    background-size: cover;
    z-index: -1;
  }

  .login-title {
    width: 28px;
    // padding: 29px 25px;   
    margin: 30px 25px;
    margin-bottom: 60px;
    font-size: 14px;
    color: #101010;
    padding-bottom: 6px;
    border-bottom: 3px solid #101010;
  }

  .login-logo {
    width: 277px;
    height: 72px;
    // padding-left: 49px;  
    margin: 0 auto;
    margin-bottom: 70px;
  }

  .login-bgc-top {
    position: relative;
    top: 0;
    left: 0;
    width: 306px;
    height: 241px;
  }

  .van-field__control {
    font-size: 16px;
    margin-left: 15px;
  }

  .situLogo {
    margin: 40px auto;
    width: 218px;
    height: 108px;
  }

  .getCode {
    // border-radius: 10px;
    background-color: #ea260e;
    border: 0.02667rem solid #ea260e;
    background: linear-gradient(90deg, #ea260e 0%, #f86b05 100%) !important;
  }

  .FormSubmit {
    margin-top: 10px;
  }

  .login-btn {
    margin-top: 50px;
    // padding: 0 30px;

    /deep/ .van-button--primary {
      background-color: #04b5c4;
      border: 0.02667rem solid #04b5c4;
      background: #04b5c4 !important;
      font-size: 14px;
    }
  }

  .footCar {
    position: fixed;
    bottom: 0px;
    right: 0px;
  }

  .text-box {
    display: flex;
    padding: 0 30px;
    justify-content: flex-end;
    margin-top: 13px;
    font-size: 13px;

    .text-box-go {
      color: #ef3f0b;
    }

    .text-box-no {
      color: #333;
    }
  }

  .login-bgc-bottom {
    position: absolute;
    bottom: -4px;
    right: 0;
    width: 100%;
    // height: 109px;
  }
}
</style>
