<template>
  <!-- 这个是编辑项目的页面 -->
  <div class="index">
    <van-nav-bar
      title="发布病例"
      fixed
      left-arrow
      @click-left="$router.back()"
    />
    <div class="contain">
      <!-- 这里边是一个表单 -->
      <el-form
        ref="reference"
        :model="reference"
        size="small"
        label-width="80px"
        label-position="top"
        :rules="rules"
      >
        <el-card class="box-card">
          <el-form-item
            label="编码"
            prop="atm_coding"
            v-if="information.userid != 837"
          >
            <el-input
              rows="2"
              v-model="reference.atm_coding"
              placeholder="请输入编码"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="发票"
            class="invoice"
            prop="invoice_img"
            v-if="information.userid != 837"
          >
            <el-upload
              :action="action"
              list-type="picture-card"
              name="file"
              :data="uploadData1"
              :on-success="invoiceHandleAvatarSuccess"
              :before-upload="wenBeforeUploadbbbbbbbbbbbbbbbbbb"
              :file-list="invoicefileList"
              :before-remove="invoiceRemove"
              ref="invoiceUrl"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>

          <el-form-item
            label="出院小结"
            prop="discharge_summary_img"
            class="discharge"
          >
            <el-upload
              :action="action"
              list-type="picture-card"
              name="file"
              :data="uploadData"
              :on-success="dischargeHandleAvatarSuccess"
              :before-upload="wenBeforeUploadaaaaaaaaaaaaaaaaaaaa"
              :file-list="dischargefileList"
              :before-remove="dischargeRemove"
              ref="dischargeUrl"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>

          <el-form-item>
            <el-button
              type="primary"
              style="background: #04b5c4; border-color: #04b5c4"
              @click="submitForm('reference')"
              >提交</el-button
            >
          </el-form-item>
        </el-card>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "PostCase",
  data() {
    return {
      information: {},
      showProgress: false,
      progressPercent: 0,
      loadProgress: 0, // 动态显示进度条
      progressFlag: false, // 关闭进度条
      dialogVisible: false,
      progressPercent: 0,
      uploadData: { key: "", token: "" },
      uploadData1: { key: "", token: "" },
      showprocess: false,
      percentlength: "",
      action: `https://up-z0.qiniup.com`, 
      //发票
      invoicefileList: [],
      invoicefileListUlr: [],
      invoiceImageUrl: "",
      invoiceShow: false,
      invoiceDisabled: false,
      //出院
      dischargefileList: [],
      dischargefileListUlr: [],
      dischargeImageUrl: "",
      dischargeShow: false,
      dischargeDisabled: false,
      reference: {
        id: "",
        atm_coding: "",
        invoice_img: "", //发票照片
        discharge_summary_img: "",
      },
      rules: {
        atm_coding: [
          { required: true, message: "请输入编码", trigger: "blur" },
        ],
        invoice_img: [
          { required: true, message: "请上传发票照片", trigger: "change" },
        ],
        discharge_summary_img: [
          { required: true, message: "请上传出院小结", trigger: "change" },
        ],
      },
    };
  },
  created() {
    // this.getMeApa();
    this.information = JSON.parse(localStorage.getItem("information")); //localStorage.getItem("information" );
    //编辑进来
    if (
      this.$route.query.id != "" &&
      this.$route.query.id != null &&
      this.$route.query.id != undefined
    ) {
      this.getmedicaldetailApi(this.$route.query.id);
    }
  },
  methods: {
    //获取个人信息
    async getMeApa() {
      const { data: data } = await this.$http.post("auth/me");
      this.information = data.data;
    },
    //病例详情
    async getmedicaldetailApi(id) {
      const { data: data } = await this.$http.post("medical/detail", {
        id,
      });
      this.reference = data.data;
      data.data.invoice_img.forEach((item) => {
        return this.invoicefileList.push({ url: item });
      });
      data.data.discharge_summary_img.forEach((item) => {
        return this.dischargefileList.push({ url: item });
      });
      this.invoicefileListUlr = data.data.invoice_img;
      this.dischargefileListUlr = data.data.discharge_summary_img;
      console.log(data);
    },
    //发票删除
    invoiceRemove(file) {
      // this.$refs.invoiceUrl.uploadFiles.forEach((v, index) => {
      //   if (file.name === v.name && file.url === v.url) {
      //     this.$refs.invoiceUrl.uploadFiles.splice(index, 1);
      //     this.invoicefileListUlr.splice(index, 1)
      //   }
      // });
      // this.$refs.invoiceUrl.uploadFiles.forEach((v, index) => {
      //   if (file.name === v.name && file.url === v.url) {
      //     this.$refs.invoiceUrl.uploadFiles.splice(index, 1);
      //   }
      // });
      // this.invoicefileListUlr = []
      // this.$refs.invoiceUrl.uploadFiles.forEach(item => {
      //   this.invoicefileListUlr.push(item.url)
      // })
      // console.log(this.invoicefileListUlr);
      this.$refs.invoiceUrl.uploadFiles.forEach((v, index) => {
        if (file.uid === v.uid && file.url === v.url) {
          // this.$refs.invoiceUrl.uploadFiles.splice(index, 1);
          this.invoicefileListUlr.splice(index, 1);
        }
      });
    },
    //发票查看
    invoiceview(file) {
      this.invoiceImageUrl = file.url;
      this.invoiceShow = true;
    },
    //发票上传成功
    invoiceHandleAvatarSuccess(res, file, fileList) {
      if (res.key != "" || res.key != undefined || res.key != null) {
        this.invoicefileListUlr.push(
          `https://resources.wsg.medcase.com.cn/${res.key}`
        );
        this.$toast("文件上传成功");
      } else {
        this.$toast.fail("上传失败");
      }
    },
    //发票上传前
    async wenBeforeUploadbbbbbbbbbbbbbbbbbb(file) {
      const is50M = file.size / 1024 / 1024 < 50;
      if (!is50M) {
        this.$message.error("上传图片大小不能超过 50MB!");
        return false;
      }
      //获取七牛云的token
      const { data: data } = await this.$http.get("system/qiniuToken");
      this.uploadData1.token = data.data.token;
      this.uploadData1.key = file.uid + file.name;
    },
    //111111111111111111111111111111111111111
    //出院上传成功
    dischargeHandleAvatarSuccess(res, file, fileList) {
      if (res.key != "" || res.key != undefined || res.key != null) {
        this.dischargefileListUlr.push(
          `https://resources.wsg.medcase.com.cn/${res.key}`
        );
        this.$toast("文件上传成功");
      } else {
        this.$toast.fail("上传失败");
      }
    },
    //出院上传前
    async wenBeforeUploadaaaaaaaaaaaaaaaaaaaa(file) {
      const is50M = file.size / 1024 / 1024 < 50;
      if (!is50M) {
        this.$message.error("上传图片大小不能超过 50MB!");
        return false;
      }
      //获取七牛云的token
      const { data: data } = await this.$http.get("system/qiniuToken");
      this.uploadData.token = data.data.token;
      this.uploadData.key = file.uid + file.name;
    },
    //出院删除
    dischargeRemove(file) {
      // this.$refs.dischargeUrl.uploadFiles.forEach((v, index) => {
      //   if (file.name === v.name && file.url === v.url) {
      //     this.$refs.dischargeUrl.uploadFiles.splice(index, 1);
      //     // this.dischargefileListUlr.splice(index, 1)
      //   }
      // });
      // this.dischargefileListUlr = []
      // this.$refs.dischargeUrl.uploadFiles.forEach(item => {
      //   this.dischargefileListUlr.push(item.url)
      // })
      this.$refs.dischargeUrl.uploadFiles.forEach((v, index) => {
        if (file.uid === v.uid && file.url === v.url) {
          // this.$refs.dischargeUrl.uploadFiles.splice(index, 1);
          this.dischargefileListUlr.splice(index, 1);
        }
      });
    },
    //出院上传时
    onProgressTest(event, file, fileList) {
      let index = 0;
      // 只有fileList 跟file 里面的uid是唯一的互相对应的
      fileList.forEach((v, i) => {
        if (v.uid === file.uid) {
          index = i;
        }
      });
      // 获取动态的进度
      let percent = parseInt(event.percent);
      this.$nextTick(() => {
        setTimeout(() => {
          if (percent >= 99) {
            // 当动态的进度超过99就获取dom 让值一直显示99直到上传成功
            let dom = document.getElementsByClassName("el-upload-list__item")[
              index
            ];
            let progressText = dom.getElementsByClassName("el-progress__text");
            if (progressText && progressText.length) {
              progressText[0].style.opacity = 0;
              progressText[0].innerText = "99%";
              progressText[0].style.opacity = 1;
            }
          }
        });
      });
    },
    // 点击提交表单
    submitForm(formName) {
      this.reference.invoice_img = this.invoicefileListUlr + "";
      this.reference.discharge_summary_img = this.dischargefileListUlr + "";
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.information.doctor) {
            this.$toast("您还未认证，请去个人中心认证个人信息");
          } else if (this.information.doctor.status == 3) {
            this.$toast("您的实名认证信息未通过，请在审核通过后发布病例。");
          } else {
            this.getsubmitApi();
          }
        } else {
          return false;
        }
      });
    },
    //提交接口
    async getsubmitApi() {
      const { data: data } = await this.$http.post(
        "auth/medical",
        this.reference
      );
      this.$message({
        message: data.message,
        type: "success",
      });
      this.$router.push({
        path: "my",
        query: {
          index: "0",
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.index {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.contain {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.index-head {
  width: 100%;
  height: 188px;
}
.box-card {
  width: 100%;
  flex: 1;
  overflow-y: auto;
  margin-bottom: 40px;
}

.invoice ::v-deep .el-upload-list--picture-card .el-upload-list__item {
  // width: 271.6px !important; //148px
  // height: 148px !important;
  width: 100% !important; //148px
  height: 148px !important;
}

.invoice ::v-deep .el-upload--picture-card {
  width: 143px !important; //148px
  height: 143px !important;
}

.discharge ::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 143px !important; //148px
  height: 143px !important;
}
.discharge ::v-deep .el-upload--picture-card {
  width: 143px !important; //148px
  height: 143px !important;
}

.chuyuan-jindu {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
