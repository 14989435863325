<template>
  <div class="search">
    <van-nav-bar
      title="病例检索"
      fixed
      left-arrow
      @click-left="$router.back()"
    />

    <div class="search-banner">
      <img src="@/assets/image/search/banner.png">
    </div>

    <div class="results">
      <div class="results-j">
        <div>检索结果：</div>
        <div class="results-active">{{dataList.length}}</div>
        <div>条病例</div>
      </div>
      <div class="results-g">
        <div>检索关键词：</div>
        <div class="results-active">{{$route.query.value}}</div>
      </div>
    </div>

    <div v-if="dataList">
      <van-empty
        class="no"
        v-if="dataList.length==0"
        description="暂无数据"
      />
      <!-- <ul
        v-else
        class="recommended-ul"
      >
        <li
          class="recommended-li"
          v-for="(item,index) in dataList.list"
          :key="index"
          @click="$router.push({path: '/home/caseDetails',query: {id:item.id} })"
        >
          <div class="recommended-li-l"></div>
          <div class="recommended-li-h"></div>
          <div class="recommended-li-text">
            {{item.title}}
          </div>
          <div class="recommended-li-name"> {{item.author}}</div>
        </li>
      </ul> -->

      <van-list
        class="recommended-ul"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-cell>
          <div
            class="recommended-li"
            v-for="(item,index) in dataList"
            :key="index"
            @click="$router.push({path: '/homeDetails',query: {id:item.id} })"
          >
            <div class="recommended-li-l"></div>
            <div class="recommended-li-h"></div>
            <div class="recommended-li-text">
              {{item.title}}
            </div>
            <div class="recommended-li-name"> {{item.author}}</div>
          </div>
        </van-cell>
      </van-list>
    </div>
  </div>
</template> 

<script>
export default {
  name: 'search',
  data () {
    return {
      limit: 10,
      page: 1,
      dataList: [],
      list: [],
      loading: false,
      finished: false,
    };
  },
  activated () {
    // this.getSearchApi()
  },
  methods: {
    //列表
    async getSearchApi () {
      const { data: data } = await this.$http.post('medical/list', {
        page: this.page,
        limit: this.limit,
        keywords: this.$route.query.value
      })
      if (this.page <= data.data.pages) {
        this.dataList.push(...data.data.list)
        this.page++
      }
      if (this.dataList.length >= data.data.total) {
        this.finished = true;
      }
    },
    //上拉刷新
    onLoad () {
      if (this.$route.query.value == '') {
        this.loading = false
        return
      }
      this.loading = true
      this.getSearchApi()
      setTimeout(() => {
        this.loading = false
      }, 1500)
    },
  }
}

</script>
<style lang="less" scoped>
.search {
  /deep/.van-cell::after {
    border: 0 !important;
  }

  /deep/ .van-cell {
    padding: 0 !important;
  }
  .search-banner {
    width: 100%;
    height: 96px;
  }

  .results {
    position: relative;
    top: 0;
    left: -4%;
    width: 108%;
    background-color: #eef8f9;
    margin-top: 18px;
    padding: 15px;
    .results-j {
      display: flex;
      align-items: center;
      margin-bottom: 23px;
      font-size: 14px;
      color: #000000;
    }
    .results-g {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #000000;
    }
    .results-active {
      color: #ed330d;
      margin-right: 5px;
    }
  }

  .recommended-ul {
    position: relative;
    padding-top: 6px;
    .recommended-li {
      position: relative;
      padding: 17px 13px 17px 26px !important;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0px 3px 8px 1px #d7e9eb;
      margin-top: 14px;

      .recommended-li-l {
        position: absolute;
        top: 0;
        left: 0;
        width: 8px;
        height: 57px;
        background-color: #04b5c4;
      }
      .recommended-li-h {
        position: absolute;
        top: 7px;
        left: 6px;
        width: 8px;
        height: 30px;
        background-color: #ffead1;
      }
      .recommended-li-text {
        color: #393939;
        font-size: 13px;
        margin-bottom: 14px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        line-height: 1.6;
      }
      .recommended-li-name {
        font-size: 13px;
        color: #fd4734;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
    }
    .recommended-li:last-child {
      margin-bottom: 18px;
    }
  }

  .no {
    margin-top: 35px;
  }
}
</style> 