<template>
  <div>
    <van-tabbar
      v-model="active"
      active-color="#0436AB"
      @change="onChange"
      fixed
      router
      v-if="tabShow"
    >
      <van-tabbar-item
        v-for="(item, index) in tabData"
        :icon="item.icon"
        :to="item.path"
        :key="index"
      >
        <span :class="defaultActive === index ? active : ''">{{
          item.title
        }}</span>
        <template slot="icon" slot-scope="props">
          <img :src="props.active ? item.active : item.normal" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
export default {
  name: "TabBar",
  props: {
    defaultActive: {
      type: Number,
      default: 0,
    },
    tabData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      active: this.defaultActive,
      tabShow: true,
    };
  },
  watch: {
    $route: "fetchData",
    deep: true,
    immediate: true,
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    onChange(index) {},
    fetchData() {
      //隐藏tababr
      if (
        this.$route.path != "/homeTopic" &&
        this.$route.path != "/liveStream" &&
        this.$route.path != "/Information" &&
        this.$route.path != "/my"
      ) {
        this.tabShow = false;
      } else {
        this.tabShow = true;
      }
      // 此方式浏览器刷新会跳转到首页
      if (this.$route.path == "/homeTopic") this.active = 0;
      // if (this.$route.path == "/liveStream") this.active = 1;
      // if (this.$route.path == "/Information") this.active = 2;
      if (this.$route.path == "/my") this.active = 1;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .van-tabbar-item__icon {
  margin-bottom: 6px !important;
}
.van-tabbar-item__icon img {
  width: 27px;
  height: 24px;
}
</style>
