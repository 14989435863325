<template>
  <div class="myCertification">
    <van-nav-bar title="认证信息" fixed @click-left="$router.back()" />
    <div class="head"><img src="@/assets/image/my/组 634@2x.png" /></div>

    <div class="myCertification-form">
      <van-form @submit="onSubmit">
        <van-field v-model="form.real_name" name="医生姓名" label="医生姓名" placeholder="请输入医生姓名"
          :rules="[{ required: true, message: '请输入医生姓名' }]" required />

        <van-field  required name="性别" :value="value" label="性别" :rules="[{ required: true, message: '请选择性别' }]"> 
          <template #input> 
            <van-radio-group v-model="form.sex" direction="horizontal">  
              <van-radio :name="1">男</van-radio>
              <van-radio :name="2">女</van-radio> 
            </van-radio-group> 
          </template> 
        </van-field> 

        <van-field v-model="form.phone" name="联系电话" label="联系电话" placeholder="请输入联系电话" :rules="[
          { required: true, message: '请输入联系电话' },
          { validator: isMobileNumber, message: '手机号码格式不正确' },
        ]" required />

        <van-field readonly clickable name="医生职称" :value="value" label="医生职称" placeholder="请选择医生职称"
          @click="showPicker = true" :rules="[{ required: true, message: '请选择医生职称' }]" required />

        <van-field v-model="form.unit" name="所在医院" label="所在医院" placeholder="输入所在医院"
          :rules="[{ required: true, message: '请输入所在医院' }]" required />
        <van-field v-model="form.department" name="所属科室" label="所属科室" placeholder="请输入所属科室"
          :rules="[{ required: true, message: '请输入所属科室' }]" required />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker show-toolbar :columns="columns" @confirm="onConfirm" @cancel="showPicker = false" />
        </van-popup>



        <!-- <van-field
          v-model="form.id_card_number"
          name="身份证号"
          label="身份证号"
          placeholder="请输入身份证号"
          :rules="[
            { required: true, message: '请输入身份证号' },
            { validator: peopleID, message: '身份证号格式不正确' },
          ]"
          required
        /> -->


        <!-- <van-field
          v-model="form.bank_number"
          name="银行卡卡号"
          label="银行卡卡号"
          placeholder="请输入银行卡卡号"
          :rules="[{ required: true, message: '请输入银行卡卡号' }]"
          required
        /> -->

        <!-- <van-field
          v-model="form.opening_bank"
          name="开户行支行"
          label="开户行支行"
          placeholder="请输入开户行支行(准确到支行信息)"
          :rules="[{ required: true, message: '请输入开户行支行' }]"
          required
        />

        <van-field
          name="certificate"
          label="工牌照片/医师资格证"
          :rules="[{ required: true, message: '请上传工牌照片' }]"
          required
        >
          <template #input>
            <van-uploader
              v-model="fileList3"
              :max-count="1"
              :after-read="afterRead3"
            />
          </template>
        </van-field> -->

        <div class="mvoe-checked">
          <van-checkbox v-model="checked" shape="square">我已阅读同意</van-checkbox>
          <div class="mvoe-checked-name" @click="show = true">
            病例集用户协议
          </div>
        </div>
        <van-popup v-model="show" style="width: 80%; max-height: 80%">
          <div class="popup-text">
            <h3 style="margin-bottom: 10px">用户注册隐私协议</h3>
            <h4>一、前言</h4>
            我们非常重视用户的隐私和个人信息的保护。因此，我们制定了本隐私协议，以明确我们在用户注册、使用我们的服务过程中，如何收集、使用、存储、共享和保护您的个人信息。请您在注册前，仔细阅读本隐私协议。
            <br />
            <h4>二、信息的收集和使用</h4>
            1.
            当您注册我们的服务时，我们可能会要求您提供一些必要的个人信息，例如：姓名、电子邮件地址、手机号码、密码等。这些信息将用于创建您的账户、提供服务和改进我们的产品。<br />
            2. 我们可能会使用您的个人信息进行以下活动：<br />
            - 验证您的身份；<br />
            - 提供和改进我们的服务； <br />
            - 发送与您相关的通知和更新； <br />
            - 进行市场分析和研究，以优化我们的产品和服务。
            <br />
            3. 我们将仅在您同意或法律法规允许的情况下，使用您的个人信息。
            <h4>三、信息的存储和保护</h4>
            1.
            我们将采取合理的技术和管理措施，保护您的个人信息不被未经授权的访问、使用、泄露、损坏或丢失。<br />
            2.
            您的个人信息将存储在我们的服务器上，我们可能将服务器设在国内或国外。我们承诺，无论存储在哪里，我们都会遵守中国的法律法规，并采取必要的安全措施。
            <h4>四、信息的共享</h4>
            1. 我们不会将您的个人信息共享给任何第三方，除非： <br />
            - 您明确同意； <br />
            - 我们需要遵守法律法规或法院命令； <br />
            -
            为了向您提供服务，我们需要与第三方合作伙伴共享您的信息，但我们会与这些合作伙伴签订严格的保密协议。
            <br />
            2.
            在某些情况下，我们可能会将您的个人信息进行匿名化或汇总，然后用于研究和开发等目的。此时，您的个人信息将无法被识别。
            <br />
            <h4>五、您的权利</h4>
            1. 您有权要求我们提供您的个人信息副本。<br />
            2. 您有权要求我们更正或删除您的个人信息。<br />
            3.
            您有权拒绝我们收集或使用您的个人信息，但这可能会影响到您使用我们的服务。<br />
            4. 如果您对我们的隐私政策有任何疑问或投诉，您可以随时联系我们。<br />
            <h4>六、隐私政策的变更</h4>
            我们可能会不时地更新本隐私政策，以适应我们的服务变更或法律法规的要求。我们会通过公告、电子邮件或其他方式通知您任何变更。请定期查看本隐私政策，以确保您了解最新的隐私保护措施。
            <h4>七、其他</h4>
            本隐私政策适用于我们所有的服务，但某些特定的服务可能有额外的隐私条款。如果有任何冲突，以特定服务的隐私条款为准。
            <h4>八、联系方式</h4>
            如果您对我们的隐私政策有任何疑问或投诉，请通过以下方式与我们联系：[xuzp@high-med.com]
            或 [18033805790]。我们将尽快回复您的请求。<br />
            感谢您花时间阅读我们的隐私政策。我们承诺将尽最大的努力保护您的隐私和个人信息
          </div>
        </van-popup>
        <div class="myCertification-btn">
          <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import getBankcardinfo from "bankcardinfo";
import { areaList } from "@vant/area-data";
export default {
  name: "certification",
  data() {
    return {
      show: false,
      checked: false,
      areaList,
      showArea: false,
      value: "",
      columns: ["住院医师", "主治医师", "副主任医师", "主任医师"],
      showPicker: false,
      // action: `http://api.henlius.netloop.cn/api/system/upload`,
      action: `https://up-z0.qiniup.com`,
      fileList1: [],
      fileList2: [],
      fileList3: [],
      fileList4: [],
      fileList5: [],
      form: {
        userTypeId: "1",
        real_name: "", //姓名
        unit: "", //工作单位
        department: "", //科室
        title: "", //职称
        phone: "", //手机号
        bank_number: "", //银行卡号
        // bank: "", //开户行
        opening_bank: "", //开户行支行
        certificate: "", //医师证
        id_card_number: "", //身份证号
        sex:''//性别
      },
    };
  },
  methods: {
    //手机号验证
    isMobileNumber(val) {
      return /^1[3456789]\d{9}$/.test(val);
    },
    //身份证号验证
    peopleID(val) {
      return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(val);
    },
    bankNumberchange(num) {
      getBankcardinfo.getBankBin(num.target._value, (err, data) => {
        console.log("提示" + err);
        if (!err) {
          this.form.bank = data.bankName;
        } else {
          this.form.bank = "";
          this.$toast("银行卡号不正确");
        }
      });
    },
    //银行卡正
    async bankZ(file) {
      // 此时可以自行将文件上传至服务器
      let formData = new FormData();
      formData.append("file", file.file);
      const { data: data } = await this.$http.post("/system/upload", formData);
      this.form.bank_1 = data.data.url;
    },
    //银行卡反
    async bankF(file) {
      // 此时可以自行将文件上传至服务器
      let formData = new FormData();
      formData.append("file", file.file);
      const { data: data } = await this.$http.post("/system/upload", formData);
      this.form.bank_2 = data.data.url;
    },
    //身份证正
    async afterRead1(file) {
      let time = Math.round(Math.random() * 80 + 20);
      let imgName = file.file.name.slice(file.file.name.lastIndexOf(".") + 1);

      file.status = "uploading";
      file.message = "上传中...";
      const { data: data } = await this.$http.get("system/qiniuToken");
      let formData = new FormData();
      formData.append("token", data.data.token);
      formData.append("file", file.file);
      formData.append("key", new Date().getTime() + time + "." + imgName); //file.file.name   Math.round(Math.random() * 80 + 20)) +
      this.$http.post("https://up-z0.qiniup.com/", formData).catch((err) => {
        console.log(err);
        this.$toast("上传成功");
        this.form.id_card1 = `https://resources.wsg.medcase.com.cn/${err.key}`;
        file.status = "done";
      });
    },
    //身份证反
    async afterRead2(file) {
      let time = Math.round(Math.random() * 80 + 20);
      let imgName = file.file.name.slice(file.file.name.lastIndexOf(".") + 1);
      //1111111111111111111111111111111111111111111
      file.status = "uploading";
      file.message = "上传中...";
      const { data: data } = await this.$http.get("system/qiniuToken");
      let formData = new FormData();
      formData.append("token", data.data.token);
      formData.append("file", file.file);
      formData.append("key", new Date().getTime() + time + "." + imgName);
      this.$http.post("https://up-z0.qiniup.com/", formData).catch((err) => {
        console.log(err);
        this.form.id_card2 = `https://resources.wsg.medcase.com.cn/${err.key}`;
        this.$toast("上传成功");
        file.status = "done";
      });
    },
    //医师资格证
    async afterRead3(file) {
      let time = Math.round(Math.random() * 80 + 20);
      let imgName = file.file.name.slice(file.file.name.lastIndexOf(".") + 1);

      file.status = "uploading";
      file.message = "上传中...";
      const { data: data } = await this.$http.get("system/qiniuToken");
      let formData = new FormData();
      formData.append("token", data.data.token);
      formData.append("file", file.file);
      formData.append("key", new Date().getTime() + time + "." + imgName);
      this.$http.post("https://up-z0.qiniup.com/", formData).catch((err) => {
        console.log(err);
        this.form.certificate = `https://resources.wsg.medcase.com.cn/${err.key}`;
        this.$toast("上传成功");
        file.status = "done";
      });
    },
    //职称选择
    onConfirm(value) {
      this.value = value;
      this.form.title = value;
      this.showPicker = false;
    },
    //提交
    async onSubmit(values) {
      if (!this.checked) {
        this.$toast("请勾选同意协议并阅读");
        return;
      }
      const { data: data } = await this.$http.post(
        "auth/certification",
        this.form
      );
      // this.$toast(data.message);
      this.$dialog
        .alert({
          title: "温馨提示",
          message: "信息已经提交，随时可以发布病例",
        })
        .then(() => {
          this.$router.push("homeTopic");
        });
    },
  },
};
</script>
<style lang="less" scoped>
.myCertification {
  padding: 46px 15px 50px; //顶部导航46px 底部导航50px 左右15
  position: relative;
  overflow: hidden;

  .head {
    width: 108%;
    position: absolute;
    top: -20px;
    left: -4%;
  }

  .myCertification-form {
    margin-top: 20px;
  }

  /deep/.van-cell {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .myCertification-btn {
    margin-top: 30px;

    /deep/.van-button {
      height: 36px;
    }

    /deep/ .van-button--info {
      color: #fff;
      background: linear-gradient(105deg, #eb280f 0%, #f86b04 100%);
      border: 1px solid #eb280f;
    }
  }
}

.mvoe-checked {
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-top: 10px;

  /deep/.van-checkbox__label {
    font-size: 14px !important;
    margin-top: 3px;
    color: #ccc !important;
  }

  /deep/.van-checkbox__icon .van-icon {
    width: 15px !important;
    height: 15px !important;
    margin-top: 3px;
    font-size: 12px;
  }

  .mvoe-checked-name {
    font-size: 14px;
    margin-left: 10px;
    color: rgb(4, 188, 196);
  }
}

.popup-text {
  padding: 15px;
}
</style>
