<template>
  <div class="my">
    <van-nav-bar title="个人中心" fixed />
    <div class="bgc"><img src="@/assets/image/my/bgc.png" /></div>

    <div class="Information">
      <div class="Information-left">
        <div class="Information-Profile">
          <div class="Information-Profile-img">
            <!-- <img
              :src="
                information.avatar
                  ? information.avatar
                  : '@/assets/image/my/Profile.png'
              "
            /> -->
            <img v-if="information.avatar" :src="information.avatar" />
            <img v-else src="@/assets/image/my/Profile.png" />
          </div>
        </div>
        <div class="Information-name">{{ information.phone }}</div>
      </div>

      <div class="Information-right">
        <!-- 0待认证 -->
        <div class="anquan" v-if="information.doctor_status == 0">
          <div class="anquan-img">
            <img src="@/assets/image/my/ren0.png" />
          </div>
          <div class="anquan-text anquan-color0">待认证</div>
        </div>
        <!-- 0待认证 -->

        <!-- 1已申请 -->
        <div class="anquan" v-if="information.doctor_status == 1">
          <div class="anquan-img">
            <img src="@/assets/image/my/ren1.png" />
          </div>
          <div class="anquan-text anquan-color1">待审核</div>
        </div>
        <!-- 1已申请 -->

        <!-- 2通过 -->
        <div class="anquan" v-if="information.doctor_status == 2">
          <div class="anquan-img">
            <img src="@/assets/image/my/anquan.png" />
          </div>
          <div class="anquan-text">认证通过</div>
        </div>
        <!-- 2通过 -->

        <!-- 3拒绝 -->
        <div class="anquan" v-if="information.doctor_status == 3">
          <div class="anquan-img">
            <img src="@/assets/image/my/ren3.png" />
          </div>
          <div class="anquan-text anquan-color3">认证驳回</div>
        </div>
        <!-- 3拒绝 -->
      </div>
    </div>

    <div class="content">
      <div class="content-function">
        <img src="@/assets/image/my/function.png" />
      </div>

      <ul class="content-ul">
        <li
          class="content-li"
          v-for="(item, index) in list"
          :key="index"
          @click="categoryBtn(index)"
        >
          <div class="content-li-box">
            <div class="content-li-box-img"><img :src="item.img" /></div>
            <div class="content-li-box-name">{{ item.name }}</div>
          </div>
          <div class="content-li-icon" v-if="index != 3">
            <van-icon name="arrow" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "my",
  data() {
    return {
      information: {},
      list: [
        { name: "我的病例", img: require("@/assets/image/my/b.png") },
        { name: "认证信息", img: require("@/assets/image/my/r.png") },
        // { name: '劳务协议', img: require('@/assets/image/my/l.png') },
        { name: "退出当前账户", img: require("@/assets/image/my/t.png") },
      ],
    };
  },
  created() {
    // this.getMeApi();
    this.information = JSON.parse(localStorage.getItem("information"));
  },
  methods: {
    async getMeApi() {
      const { data: data } = await this.$http.post("auth/me");
      this.information = data.data;
    },
    categoryBtn(i) {
      if (i == 0) this.$router.push({ path: "/my/myCase" });
      if (i == 1) this.$router.push("/my/myCertification");
      // if (i == 2) this.$router.push("/my/myAgreement");
      if (i == 2) {
        this.$dialog
          .confirm({
            title: "退出登录",
            message: "您是否要退出登录？",
          })
          .then(() => {
            localStorage.removeItem("token");
            localStorage.removeItem("information");
            this.$router.replace("/login");
            this.$toast("退出成功");
          })
          .catch(() => {});
      }
    },
  },
};
</script>
<style lang="less" scoped>
.my {
  position: relative;
  .bgc {
    width: 108%;
    height: 225px;
    position: absolute;
    top: -48px;
    left: -4%;
    // z-index: 9999;
  }

  .Information {
    position: relative;
    top: 45px;
    // z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .Information-left {
      display: flex;
      align-items: center;
    }
    .Information-right {
    }
    .Information-Profile {
      position: relative;
      width: 63px;
      height: 59px;
      background-color: #04b5c4;
      border-radius: 50%;
      overflow: hidden;
      .Information-Profile-img {
        position: relative;
        top: 0;
        left: 6px;
        width: 57px;
        height: 59px;
      }
    }
    .Information-name {
      margin-left: 12px;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }
  }

  .content {
    position: relative;
    margin-top: 72px;
    .content-function {
      width: 345px;
      height: 64px;
    }
    .content-ul {
      margin-top: 25px;
      .content-li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 22px 16px;
        margin-bottom: 20px;
        background-color: #f4fafc;
        border-radius: 2px;
        .content-li-box {
          display: flex;
          align-items: center;
          .content-li-box-img {
            width: 18px;
            height: 18px;
          }
          .content-li-box-name {
            margin-left: 16px;
          }
        }
        .content-li-icon {
          color: #272636;
          font-size: 14px;
        }
      }
    }
    .exit {
      margin: 82px 0 110px;
      background-color: #f4fafc;
      color: #72b0b5;
      padding: 22px;
      text-align: center;
    }
  }

  .anquan {
    display: flex;
  }
  .anquan-img {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    margin-top: 2px;
  }
  .anquan-color0 {
    color: #5b9ee1 !important;
  }
  .anquan-color1 {
    color: #ee8429 !important;
  }
  .anquan-color3 {
    color: #ff0303 !important;
  }
  .anquan-text {
    font-size: 12px;
    color: #44b24a;
  }
}
</style>
