<template>
  <div class="live">
    <van-nav-bar
      title="精粹直播"
      fixed
    />
    <div class="banner"><img src="@/assets/image/live/banner.png"></div>

    <div class="live-content">
      <div>
        <van-empty
          class="no"
          v-if="false"
          description="暂无数据"
        />
        <!-- <ul
          v-else
          class="live-content-ul"
        >
          <li
            class="live-content-li"
            v-for="(item,index) in 10"
            :key="index"
          >
            <div class="live-content-li-img">
              <div class="live-content-li-img-bgc">
                <img src="@/assets/image/live/组 645@2x.png">
              </div>
              <div class="live-content-li-img-bf">
                <img src="@/assets/image/live/bf.png">
              </div>
            </div>
            <div class="live-content-li-box">
              <div class="live-content-li-box-text">
                炎症驱动动脉粥样硬化并导致心血管疾病。最近的大规模随机试验表明，免疫调...
              </div>
              <div class="live-content-li-box-zhibo">
                <div class="live-content-li-box-zhibo-icon">
                  <img src="@/assets/image/live/zb.gif">
                </div>
                <div class="live-content-li-box-zhibo-text">
                  <div
                    v-if="true"
                    class="active-z"
                  >正在直播</div>
                  <div
                    v-if="false"
                    class="active-d"
                  >待直播</div>
                  <div
                    v-if="false"
                    class="active-h"
                  >直播回看</div>
                </div>
              </div>
            </div>
          </li>
        </ul> -->

        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          class="live-content-ul"
        >
          <van-cell>
            <div
              class="live-content-li"
              v-for="(item,index) in dataList"
              :key="index"
              @click="liveOppen(item.live_link)"
            >
              <div class="live-content-li-img">
                <div class="live-content-li-img-bgc">
                  <img :src="item.cover">
                </div>
                <div class="live-content-li-img-bf">
                  <img src="@/assets/image/live/bf.png">
                </div>
              </div>
              <div class="live-content-li-box">
                <div class="live-content-li-box-text">
                  {{item.name}}
                </div>
                <div class="live-content-li-box-zhibo">
                  <div
                    class="live-content-li-box-zhibo-icon"
                    v-if="item.status==1"
                  >
                    <img src="@/assets/image/live/zb.gif">
                  </div>
                  <div class="live-content-li-box-zhibo-text">
                    <div
                      v-if="item.status==1"
                      class="active-z"
                    >正在直播</div>
                    <div
                      v-if="item.status==0"
                      class="active-d"
                    >待直播</div>
                    <div
                      v-if="item.status==2"
                      class="active-h"
                    >直播回看</div>
                  </div>
                </div>
              </div>
            </div>
          </van-cell>
        </van-list>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'liveStream',
  data () {
    return {
      limit: 10,
      page: 1,
      dataList: [],
      list: [],
      loading: false,
      finished: false,
    }
  },
  created () { },
  methods: {
    //直播接口
    async getLiveListApi () {
      const { data: data } = await this.$http.get('live/list', {
        params: {
          page: this.page,
          limit: this.limit,
        }
      })
      if (this.page <= data.data.pages) {
        this.dataList.push(...data.data.list)
        this.page++
      }
      if (this.dataList.length >= data.data.total) {
        this.finished = true;
      }
    },
    //加载
    async onLoad () {
      this.loading = true
      this.getLiveListApi()
      setTimeout(() => {
        this.loading = false
      }, 1500)
    },
    //直播
    liveOppen (ulr) {
      window.open(ulr)
    },
  },
}

</script>
<style lang="less" scoped>
.live {
  padding-bottom: 50px;
  /deep/.van-cell::after {
    border: 0 !important;
  }

  /deep/ .van-cell {
    padding: 0 !important;
  }

  .banner {
    width: 100%;
    height: 96px;
    margin-bottom: 10px;
  }
  .live-content {
    .live-content-ul {
      .live-content-li {
        position: relative;
        display: flex;
        margin-top: 22px;
        background-color: #fff;
        &:last-child {
          margin-bottom: 18px;
        }
        .live-content-li-img {
          position: relative;
          width: 158px;
          height: 89px;
          margin-right: 15px;
          border-radius: 4px;
          overflow: hidden;
          .live-content-li-img-bgc {
            width: 158px;
            height: 89px;
          }
          .live-content-li-img-bf {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 24px;
            height: 24px;
          }
        }
        //
        .live-content-li-box {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .live-content-li-box-text {
            height: 70px;
            color: #393939;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }
          // 、、、
          .live-content-li-box-zhibo {
            margin-top: 3px;
            display: flex;
            .live-content-li-box-zhibo-icon {
              margin-top: 2px;
              width: 19px;
              height: 19px;
            }
            .live-content-li-box-zhibo-text {
              flex: 1;
              margin-left: 3px;
              font-size: 13px;
              .active-z {
                color: #0fd3ce;
              }
              .active-d {
                color: #ff9539;
              }
              .active-h {
                color: #22798f;
              }
            }
          }
        }
      }
    }
  }

  .no {
    margin-top: 80px;
  }
}
</style>