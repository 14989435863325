<template>
  <div class="caseDetails">
    <van-nav-bar
      title="病例详情"
      fixed
      left-arrow
      @click-left="$router.go(-1)"
    />

    <div class="atm" v-if="information.userid != 837">
      编码:{{ dataList.atm_coding }}
    </div>
    <div v-if="information.userid != 837">
      <div class="fapiao">发票</div>
      <ul class="fapiao-ul" v-if="dataList">
        <li class="fapiao-li" v-for="(item, index) in dataList.invoice_img">
          <img class="fapiao-li-img" :src="item" />
        </li>
      </ul>
    </div>
    <div class="xiaojie">出院小结</div>
    <ul class="xiaojie-ul" v-if="dataList">
      <li
        class="xiaojie-li"
        v-for="(item, index) in dataList.discharge_summary_img"
      >
        <img class="xiaojie-li-img" :src="item" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "homeDetails",
  data() {
    return {
      dataList: {},
      information: {},
    };
  },
  created() {
    this.information = JSON.parse(localStorage.getItem("information")); //localStorage.getItem("information" );
    this.getMedicalDetail();
  },
  methods: {
    async getMedicalDetail() {
      const { data: data } = await this.$http.post("/medical/detail", {
        id: this.$route.query.id,
      });
      this.dataList = data.data;
    },
    //下载
    download(item) {
      window.open(item.url);
    },
  },
};
</script>
<style lang="less" scoped>
.atm {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.fapiao-ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.fapiao {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}
.fapiao-li {
  margin-left: 8px;
}
.fapiao-li-img {
  width: 310px;
  height: 180px;
}

.xiaojie-ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.xiaojie {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 20px;
}
.xiaojie-li {
  margin-left: 8px;
}
.xiaojie-li-img {
  width: 310px;
  height: 180px;
}
.caseDetails {
  // overflow: hidden;
  .caseDetails-h {
    margin-top: 10px;
    font-size: 18px;
    color: #333333;
  }
  .caseDetails-name {
    margin: 20px 0 13px;
    font-size: 13px;
    color: #888888;
  }
  .caseDetails-time {
    font-size: 13px;
    color: #b3b3b3;
    margin-bottom: 32px;
  }
  //摘要
  .abstract {
    margin-bottom: 24px;
    .abstract-h {
      font-size: 14px;
      color: #000;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .abstract-box {
      padding: 25px 20px;
      border: 1px solid #007981;
      .abstract-box-content {
        .abstract-box-content-name {
          font-size: 14px;
          color: #333333;
          font-weight: 600;
          margin-bottom: 20px;
        }
        .abstract-box-content-text {
          line-height: 1.6;
          margin-bottom: 34px;
        }
        .abstract-box-content-text-z {
          line-height: 1.6;
        }
      }
    }
  }
  //正文
  .right-text {
    position: relative;
    margin-bottom: 38px;
    .right-text-content {
      margin-top: 21px;
    }
  }
  .right-text-box {
    width: 108%;
    position: relative;
    top: 0;
    left: -4%;
    padding: 12px 0;
    background-color: #eef8f9;
    .right-text-box-name {
      margin-left: 15px;
      font-size: 14px;
      color: #000000;
      font-weight: 600;
    }
  }
  .data {
    position: relative;
    margin-bottom: 55px;
    .data-ul {
      margin-top: 10px;
      .data-li {
        padding: 18px 12px;
        background-color: #f6f7ff;
        margin-top: 18px;
      }
    }
  }
  .no {
    margin-top: 20px;
  }
}
</style>
