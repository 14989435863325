<template>
  <div class="myCertification">
    <van-nav-bar
      title="认证信息"
      fixed 
      left-arrow
      @click-left="$router.back()"
    />
    <div class="head"><img src="@/assets/image/my/组 634@2x.png" /></div>

    <div class="myCertification-form">
      <van-form @submit="onSubmit" class="van-form">
        <van-field
          required 
          v-model="form.real_name"
          name="医生姓名"
          label="医生姓名"
          placeholder="请输入医生姓名"
          :rules="[{ required: true, message: '请输入医生姓名' }]"
        />

        <van-field  required name="性别" :value="value" label="性别" :rules="[{ required: true, message: '请选择性别' }]"> 
          <template #input> 
            <van-radio-group v-model="form.sex" direction="horizontal">  
              <van-radio :name="1">男</van-radio>
              <van-radio :name="2">女</van-radio> 
            </van-radio-group>  
          </template>
        </van-field> 

          <van-field
           required
          v-model="form.phone"
          name="联系电话"
          label="联系电话"
          placeholder="请输入联系电话"
          :rules="[
            { required: true, message: '请输入联系电话' }, 
            { validator: isMobileNumber, message: '手机号码格式不正确' },
          ]"
        />

        <van-field
          required
          readonly
          clickable
          name="医生职称"
          :value="value"
          label="医生职称"
          placeholder="请选择医生职称"
          @click="showPicker = true"
          :rules="[{ required: true, message: '请选择医生职称' }]" 
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>

        <van-field
          required
          v-model="form.unit"
          name="所在医院"
          label="所在医院"
          placeholder="请输入所在医院"
          :rules="[{ required: true, message: '请输入所在医院' }]" 
        />
        <van-field
          required
          v-model="form.department"
          name="所属科室"
          label="所属科室"
          placeholder="请输入所属科室"
          :rules="[{ required: true, message: '请输入所属科室' }]"
        />


 
        <!-- <van-field
          v-model="form.id_card_number"
          name="身份证号"
          label="身份证号"
          placeholder="请输入身份证号"
          :rules="[
            { required: true, message: '请输入身份证号' },
            { validator: peopleID, message: '身份证号格式不正确' },
          ]"
        /> -->



        <!-- <van-field
          v-model="form.bank_number"
          name="银行卡卡号"
          label="银行卡卡号"
          placeholder="请输入银行卡卡号"
          :rules="[{ required: true, message: '请输入银行卡卡号' }]"
        /> -->

        <!-- <van-field
          v-model="form.opening_bank"
          name="开户行支行"
          label="开户行支行"
          placeholder="请输入开户行支行(准确到支行信息)"
          :rules="[{ required: true, message: '请输入开户行支行' }]"
        /> -->

        <!-- <van-field
          name="certificate"
          label="工牌照片"
          :rules="[{ required: true, message: '请上传工牌照片' }]"
        >
          <template #input>
            <van-uploader
              ref="uploader"
              v-model="fileList3"
              :max-count="1"
              :after-read="afterRead3"
            />
          </template>
        </van-field> --> 

        <!-- v-if="information.doctor_status!=2" -->
        <div class="myCertification-btn">
          <van-button round block type="info" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
    <!-- <img src="https://resources.wsg.medcase.com.cn/Fss0NfzdcYHlAx3yttBTJIKQF3es"> -->
  </div>
</template>

<script>
import getBankcardinfo from "bankcardinfo";
export default {
  name: "myCertification",
  data() {
    return {
      url: "",
      showPicker: false,
      value: "",
      columns: ["住院医师", "主治医师", "副主任医师", "主任医师"],
      value: "",
      form: {
        userTypeId: "1",
        real_name: "", //姓名
        unit: "", //工作单位
        department: "", //科室
        title: "", //职称
        phone: "", //手机号
        bank_number: "", //银行卡号
        // bank: "", //开户行
        opening_bank: "", //开户行支行
        certificate: "", //医师证
        id_card_number: "", //身份证号
        sex:''//性别 
      },
      fileList1: [{ url: "" }],
      fileList2: [{ url: "" }],
      fileList3: [{ url: "" }],
      fileList4: [{ url: "" }],
      fileList5: [{ url: "" }],
    };
  },
  created() {
    this.geTauthMyCert();
  },
  methods: {
    //手机号验证
    isMobileNumber(val) {
      return /^1[3456789]\d{9}$/.test(val);
    },
    //身份证号验证
    peopleID(val) {
      return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(val);
    },
    bankNumberchange(num) {
      // let num = '6222005865412565805'
      if (num.length < 19) {
        this.$message.error("银行卡号不正确");
        return;
      }
      getBankcardinfo.getBankBin(num.target._value, (err, data) => {
        console.log(err);
        if (!err) {
          this.form.bank = data.bankName;
        } else {
          this.form.bank = "";
          this.$toast(err);
        }
      });
    },
    //银行卡正
    async bankZ(file) {
      // 此时可以自行将文件上传至服务器
      let formData = new FormData();
      formData.append("file", file.file);
      const { data: data } = await this.$http.post("/system/upload", formData);
      this.form.bank_1 = data.data.url;
    },
    //银行卡反
    async bankF(file) {
      // 此时可以自行将文件上传至服务器
      let formData = new FormData();
      formData.append("file", file.file);
      const { data: data } = await this.$http.post("/system/upload", formData);
      this.form.bank_2 = data.data.url;
    },
    //身份证正
    async afterRead1(file) {
      let time = Math.round(Math.random() * 80 + 20);
      let imgName = file.file.name.slice(file.file.name.lastIndexOf(".") + 1);
      //1111111111111111111111111111111111111111111
      file.status = "uploading";
      file.message = "上传中...";
      const { data: data } = await this.$http.get("system/qiniuToken");
      let formData = new FormData();
      formData.append("token", data.data.token);
      formData.append("file", file.file);
      formData.append("key", new Date().getTime() + time + "." + imgName);
      this.$http.post("https://up-z0.qiniup.com/", formData).catch((err) => {
        this.$toast("上传成功");
        this.form.id_card1 = `https://resources.wsg.medcase.com.cn/${err.key}`;
        file.status = "done";
      });
    },
    //身份证反
    async afterRead2(file) {
      let time = Math.round(Math.random() * 80 + 20);
      let imgName = file.file.name.slice(file.file.name.lastIndexOf(".") + 1);
      //1111111111111111111111111111111111111111111
      file.status = "uploading";
      file.message = "上传中...";
      const { data: data } = await this.$http.get("system/qiniuToken");
      let formData = new FormData();
      formData.append("token", data.data.token);
      formData.append("file", file.file);
      formData.append("key", new Date().getTime() + time + "." + imgName);
      this.$http.post("https://up-z0.qiniup.com/", formData).catch((err) => {
        this.$toast("上传成功");
        this.form.id_card2 = `https://resources.wsg.medcase.com.cn/${err.key}`;
        file.status = "done";
      });
    },
    //医师资格证
    async afterRead3(file) {
      let time = Math.round(Math.random() * 80 + 20);
      let imgName = file.file.name.slice(file.file.name.lastIndexOf(".") + 1);
      //1111111111111111111111111111111111111111111
      file.status = "uploading";
      file.message = "上传中...";
      const { data: data } = await this.$http.get("system/qiniuToken");
      let formData = new FormData();
      formData.append("token", data.data.token);
      formData.append("file", file.file);
      formData.append("key", new Date().getTime() + time + "." + imgName);
      this.$http.post("https://up-z0.qiniup.com/", formData).catch((err) => {
        this.$toast("上传成功");
        this.form.certificate = `https://resources.wsg.medcase.com.cn/${err.key}`;
        file.status = "done";
      });
    },
    //获取认证信息
    async geTauthMyCert() {
      const { data: data } = await this.$http.get("auth/myCert");
      if (data.data != "" && data.data != undefined && data.data != null) {
        this.form = data.data;
      }
      this.value = data.data.title;
      this.fileList1[0].url = data.data.id_card1;
      this.fileList2[0].url = data.data.id_card2;
      this.fileList3[0].url = data.data.certificate;
    },
    //职称选择
    onConfirm(value) {
      this.value = value;
      this.form.title = value;
      this.showPicker = false;
    },
    //提交
    async onSubmit(values) {
      const { data: data } = await this.$http.post(
        "auth/certification",
        this.form
      );
      this.$toast(data.message);
      this.$router.push("/homeTopic");
    },
  },
};
</script>
<style lang="less" scoped>
.myCertification {
  position: relative;
  .van-form {
    margin-bottom: 20px;
  }
  .head {
    width: 108%;
    position: absolute;
    top: -20px;
    left: -4%;
  }

  .myCertification-form {
    margin-top: 20px;
  }

  /deep/.van-cell {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .myCertification-btn {
    margin-top: 30px;
    /deep/.van-button {
      height: 36px;
    }
    /deep/ .van-button--info {
      color: #fff;
      background: linear-gradient(105deg, #eb280f 0%, #f86b04 100%);
      border: 1px solid #eb280f;
    }
  }
}
</style>
