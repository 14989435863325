var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my"},[_c('van-nav-bar',{attrs:{"title":"个人中心","fixed":""}}),_vm._m(0),_c('div',{staticClass:"Information"},[_c('div',{staticClass:"Information-left"},[_c('div',{staticClass:"Information-Profile"},[_c('div',{staticClass:"Information-Profile-img"},[(_vm.information.avatar)?_c('img',{attrs:{"src":_vm.information.avatar}}):_c('img',{attrs:{"src":require("@/assets/image/my/Profile.png")}})])]),_c('div',{staticClass:"Information-name"},[_vm._v(_vm._s(_vm.information.phone))])]),_c('div',{staticClass:"Information-right"},[(_vm.information.doctor_status == 0)?_c('div',{staticClass:"anquan"},[_vm._m(1),_c('div',{staticClass:"anquan-text anquan-color0"},[_vm._v("待认证")])]):_vm._e(),(_vm.information.doctor_status == 1)?_c('div',{staticClass:"anquan"},[_vm._m(2),_c('div',{staticClass:"anquan-text anquan-color1"},[_vm._v("待审核")])]):_vm._e(),(_vm.information.doctor_status == 2)?_c('div',{staticClass:"anquan"},[_vm._m(3),_c('div',{staticClass:"anquan-text"},[_vm._v("认证通过")])]):_vm._e(),(_vm.information.doctor_status == 3)?_c('div',{staticClass:"anquan"},[_vm._m(4),_c('div',{staticClass:"anquan-text anquan-color3"},[_vm._v("认证驳回")])]):_vm._e()])]),_c('div',{staticClass:"content"},[_vm._m(5),_c('ul',{staticClass:"content-ul"},_vm._l((_vm.list),function(item,index){return _c('li',{key:index,staticClass:"content-li",on:{"click":function($event){return _vm.categoryBtn(index)}}},[_c('div',{staticClass:"content-li-box"},[_c('div',{staticClass:"content-li-box-img"},[_c('img',{attrs:{"src":item.img}})]),_c('div',{staticClass:"content-li-box-name"},[_vm._v(_vm._s(item.name))])]),(index != 3)?_c('div',{staticClass:"content-li-icon"},[_c('van-icon',{attrs:{"name":"arrow"}})],1):_vm._e()])}),0)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bgc"},[_c('img',{attrs:{"src":require("@/assets/image/my/bgc.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"anquan-img"},[_c('img',{attrs:{"src":require("@/assets/image/my/ren0.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"anquan-img"},[_c('img',{attrs:{"src":require("@/assets/image/my/ren1.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"anquan-img"},[_c('img',{attrs:{"src":require("@/assets/image/my/anquan.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"anquan-img"},[_c('img',{attrs:{"src":require("@/assets/image/my/ren3.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-function"},[_c('img',{attrs:{"src":require("@/assets/image/my/function.png")}})])
}]

export { render, staticRenderFns }