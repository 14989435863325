<template>
  <div class="Information">
    <van-nav-bar
      title="热点资讯"
      fixed
    />
    <div class="banner"><img src="@/assets/image/Information/banner.png"></div>
    <div>
      <van-empty
        class="no"
        v-if="false"
        description="暂无数据"
      />
      <!-- <ul
        
      >
        <li
          class="topic-li"
          v-for="(item,index) in 5"
          :key="index"
          @click="$router.push({path: '/Information/InformationDetails',query: {} })"
        >
          <div class="topic-img"><img src=""></div>
          <div class="topic-box">
            <div class="topic-box-text">
              中国临床案例成果数据库优秀案例征集遴选活动中国临床案例成果数据库优秀案例征集遴选活动</div>
            <div class="topic-box-con">
              <div class="topic-box-con-time">2021-10-26 15:31</div>
              <div class="topic-box-con-btn">
                <div class="topic-box-con-btn-name">详情</div>
                <div>
                  <van-icon name="arrow" />
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul> -->
      <van-list
        v-else
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        class="topic-ul"
      >
        <van-cell
          v-for="(item,index) in dataList"
          :key="index"
          @click="$router.push({path: '/InformationDetails',query: {id:item.id} })"
        >
          <div class="topic-li">
            <div class="topic-img">
              <img :src="item.cover_pic">
            </div>
            <div class="topic-box">
              <div class="topic-box-text">
                {{item.title}}
              </div>
              <div class="topic-box-con">
                <div class="topic-box-con-time">{{item.created_at}}</div>
                <div class="topic-box-con-btn">
                  <div class="topic-box-con-btn-name">详情</div>
                  <div>
                    <van-icon name="arrow" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-cell>
      </van-list>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Information',
  data () {
    return {
      limit: 10,
      page: 1,
      dataList: [],
      loading: false,
      finished: false,
    }
  },
  methods: {
    async getHotNews () {
      const { data: data } = await this.$http.get('hot/news', {
        params: {
          page: this.page,
          limit: this.limit,
        }
      })
      if (this.page <= data.data.pages) {
        this.dataList.push(...data.data.list)
        this.page++
        console.log(this.page);
      }
      if (this.dataList.length >= data.data.total) {
        this.finished = true;
      }
    },
    //加载
    async onLoad () {
      this.loading = true
      this.getHotNews()
      setTimeout(() => {
        this.loading = false
      }, 1500)
    },
  }

}

</script>
<style lang="less" scoped>
.Information {
  padding-bottom: 50px;
  /deep/.van-cell::after {
    border: 0 !important;
  }

  /deep/ .van-cell {
    padding: 0 !important;
  }
  .banner {
    width: 100%;
    height: 96px;
  }

  .topic-ul {
    .topic-li {
      margin-top: 20px;
      display: flex;
      padding-bottom: 17px;
      background-image: linear-gradient(
        to right,
        #cddadb 35%,
        rgba(255, 255, 255, 0) 0%
      ) !important; /* 35%设置虚线点x轴上的长度 */
      background-position: bottom !important; /* top配置上边框位置的虚线 */
      background-size: 15px 1px !important; /* 第一个参数设置虚线点的间距；第二个参数设置虚线点y轴上的长度 */
      background-repeat: repeat-x !important;

      &:last-child {
        background-image: linear-gradient(
          to right,
          #fff 35%,
          rgba(255, 255, 255, 0) 0%
        ); /* 35%设置虚线点x轴上的长度 */
        background-position: bottom; /* top配置上边框位置的虚线 */
        background-size: 15px 0px; /* 第一个参数设置虚线点的间距；第二个参数设置虚线点y轴上的长度 */
        background-repeat: repeat-x;
      }

      .topic-img {
        width: 114px;
        height: 76px;
        padding: 7px 3px;
        margin-right: 10px;
        border-radius: 5px;
        overflow: hidden;
      }
      .topic-box {
        flex: 1;
        padding: 5.5px 13px 3px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .topic-box-text {
          font-size: 13px;
          font-weight: 600;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        .topic-box-con {
          display: flex;
          // align-items: center;
          align-items: flex-end;
          justify-content: space-between;
          font-size: 12px;
          .topic-box-con-time {
            color: #717171;
            margin-bottom: 2px;
          }
          .topic-box-con-btn {
            width: 52px;
            height: 24px;
            color: #04b5c4;
            background-color: #e4f9fb;
            // padding: 2px 1px 1px 5px;
            border: 1px solid #04b5c4;
            border-radius: 3px;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            .topic-box-con-btn-name {
              // margin-bottom: 1px;
            }
          }
        }
      }
    }
    .topic-li:last-child {
      border: 0px;
    }
  }
}
</style>