<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>
<script>
export default {
  name: 'app',
  created () {
    // let move = "http://xinda.mobile.netloop.cn/home"
    // let pc = "http://xinda.netloop.cn/home"
    // let url = window.location.href;
    // if (this._isMobile()) { } else {
    //   console.log("移动端");
    //   if (url.indexOf("mobile") != -1) {
    //     location.href = pc
    //   }
    // }
  },
  methods: {
    //移动端  pc判断
    _isMobile () {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  }
}
</script>   
<style lang="less">
#app {
  position: relative;
  font-size: 14px;
  box-sizing: border-box;
}
</style>
