import Vue from 'vue'
import VueRouter from 'vue-router'
import label from '@/views/label.vue'
import liveStream from '@/views/liveStream/index.vue'
import Information from '@/views/Information/index.vue'
import my from '@/views/my/index.vue'

import login from '@/views/login/index.vue'
import password from '@/views/password/index.vue'
import register from '@/views/register/index.vue'
import certification from '@/views/certification/index.vue'


import homeTopic from '@/views/home/homeTopic/index.vue'
import homeDetails from '@/views/home/homeDetails/index.vue'
import InformationDetails from '@/views/Information/InformationDetails/index.vue'
import search from '@/views/external/search/index.vue'
import postCase from '@/views/external/postCase/index.vue'


import myCase from '@/views/my/myCase/index.vue'
import myCertification from '@/views/my/myCertification/index.vue'
import myAgreement from '@/views/my/myAgreement/index.vue'

import questionnaire from '@/views/questionnaire/index.vue'
import questionnaireDel from '@/views/questionnaire/questionnaireDel.vue'



//! 解决路由重复点击    
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [{
  path: '/',
  redirect: '/homeTopic'
},
{
  path: "/homeTopic",
  name: 'label',
  component: label,
  children: [
    // {
    //     path: '/home',
    //     name: 'home',
    //     component: home,
    //     meta: {
    //         keepAlive: true // 需要被缓存
    //     }
    // },
    //home下  
    {
      path: '/homeTopic',
      name: 'homeTopic',
      component: homeTopic,
      meta: {
        keepAlive: true // 需要被缓存   
      }
    },
    {
      path: '/liveStream',
      name: 'liveStream',
      component: liveStream
    },
    {
      path: '/Information',
      name: 'Information',
      component: Information,
      meta: {
        keepAlive: true // 需要被缓存  
      }
    },
    {
      path: '/my',
      name: 'my',
      component: my
    },
    {
      path: '/homeDetails',
      name: 'homeDetails',
      component: homeDetails,
    },
    //资讯下 
    {
      path: '/InformationDetails',
      name: 'InformationDetails',
      component: InformationDetails,
    },
    //个人中心下   
    {
      path: '/my/myCase',
      name: 'myCase',
      component: myCase,
      meta: {
        keepAlive: true // 需要被缓存  
      }
    },
    {
      path: '/questionnaire',
      name: 'questionnaire',
      component: questionnaire,
    },
    {
      path: '/questionnaireDel',
      name: 'questionnaireDel',
      component: questionnaireDel,
    },
    {
      path: '/my/myCertification',
      name: 'myCertification',
      component: myCertification,
    },
    {
      path: '/my/myAgreement',
      name: 'myAgreement',
      component: myAgreement,
    },
    //外部  
    {
      path: '/search',
      name: 'search',
      component: search,
      meta: {
        keepAlive: true // 需要被缓存  
      }
    },
    {
      path: '/postCase',
      name: 'postCase',
      component: postCase,
    },
  ]
},
{
  path: '/login',
  name: 'login',
  component: login
},
{
  path: '/register',
  name: 'register',
  component: register
},
{
  path: '/certification',
  name: 'certification',
  component: certification
},
{
  path: '/password',
  name: 'password',
  component: password
},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior(to, from, savedPosition) {
  //      return { x: 0, y: 0 } // x控制左右，y控制上下    return 期望滚动到哪个的位置 
  // } 
})

// 路由   
router.beforeEach((to, form, next) => {
  if (to.path == '/my' || to.path == '/questionnaire') {
    if (!localStorage.getItem('token')) {
      router.push('/login');
    } else {
      next()
    }
  } else {
    next()
  }
})

Vue.use(VueRouter)
export default router