<template>
  <div class="label">
    <router-view />
    <TabBar :tabData="tabbarList" />
  </div>
</template>

<script>
import TabBar from "@/components/tabBar/index.vue";
export default {
  name: "",
  components: {
    TabBar,
  },
  data() {
    return {
      tabbarList: [
        {
          title: "首页",
          path: { name: "homeTopic" },
          normal: require("@/assets/image/tabbar/home.png"),
          active: require("@/assets/image/tabbar/home-active.png"),
        },
        // {
        //   title: '精粹直播',
        //   path: { name: 'liveStream' },
        //   normal: require('@/assets/image/tabbar/live.png'),
        //   active: require('@/assets/image/tabbar/live-active.png')
        // },
        // {
        //   title: '热点资讯',
        //   path: { name: 'Information' },
        //   normal: require('@/assets/image/tabbar/Information.png'),
        //   active: require('@/assets/image/tabbar/Information-active.png')
        // },
        {
          title: "个人中心",
          path: { name: "my" },
          normal: require("@/assets/image/tabbar/my.png"),
          active: require("@/assets/image/tabbar/my-active.png"),
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.label {
  // padding: 46px 15px 50px; //顶部导航46px 底部导航50px 左右15
  padding: 46px 15px 0px; //顶部导航46px 底部导航50px 左右15
}
//顶部返回按钮
.van-nav-bar .van-icon {
  color: #000000 !important;
}
</style>
