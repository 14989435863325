import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/base.css'
Vue.config.productionTip = false

//接口
import http from '@/utils/cesguToken'
Vue.prototype.$http = http


import 'amfe-flexible'; //适配

//富文本解析
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css' // 引入样式
import 'quill/dist/quill.snow.css' // snow theme
import 'quill/dist/quill.bubble.css' // bubble theme
Vue.use(VueQuillEditor, /* { default global options } */ )

//节流指令
import prevent from './utils/throttle'
Vue.use(prevent);

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

//element 跳转未验证
import { scrollToView } from '@/utils/elForm'
Vue.prototype.scrollToView = scrollToView

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')