<template>
  <div class="InformationDetails">
    <van-nav-bar
      title="病例详情"
      fixed
      left-arrow
      @click-left="$router.back()"
    />
    <div class="InformationDetails-h">
      {{content.title}}
    </div>
    <div class="InformationDetails-time"> {{content.created_at}}</div>

    <div>
      <van-empty
        class="no"
        v-if="!content.content"
        description="暂无数据"
      />
      <div
        v-else
        class="InformationDetails-html"
        v-html="content.content"
      >

      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'InformationDetails',
  data () {
    return {
      content: {}
    }
  },
  created () {
    this.getHotDetail()
  },
  methods: {
    async getHotDetail () {
      const { data: data } = await this.$http.get('hot/detail', {
        params: {
          id: this.$route.query.id
        }
      })
      this.content = data.data
    }
  }
}

</script>
<style lang="less" scoped>
.InformationDetails {
  padding-bottom: 15px;
  .InformationDetails-h {
    margin-top: 10px;
    font-size: 18px;
    color: #333333;
  }

  .InformationDetails-time {
    font-size: 13px;
    color: #b3b3b3;
    padding: 28px 0 32px;
  }

  .InformationDetails-html {
    line-height: 1.7;
  }

  .no {
    margin-top: 40px;
  }
}
</style>