<template>
  <div class="my">
    <van-nav-bar
      title="我的病例"
      fixed
      left-arrow
      @click-left="$router.back()"
    />
    <div class="head"><img src="@/assets/image/my/组 634@2x.png" /></div>
    <div class="tabs">
      <van-tabs v-model="active" class="tabs-van" @click="onClick">
        <van-tab title="待审核">
          <div class="tabs1-con">
            <van-empty
              class="no"
              v-if="dataList.length == 0"
              description="暂无数据"
            />
            <ul v-else class="tabs-ul">
              <li
                class="tabs-ul-li"
                v-for="(item, index) in dataList"
                :key="index"
              >
                <!-- 上 -->
                <div class="tabs-ul-li-top">
                  <div class="tabs-ul-li-top-img">
                    <img src="@/assets/image/my/tuoyuan.png" />
                  </div>
                  <div class="tabs-ul-li-top-name">{{ item.name }}</div>
                  <div class="tabs-ul-li-top-time">{{ item.created_at }}</div>
                </div>
                <!-- 中 -->
                <div class="tabs-ul-li-cen">
                  <div class="tabs-ul-li-cen-flex">
                    <div>{{ item.sex_text }}</div>
                    <div class="tabs-ul-li-cen-flex-b">性别</div>
                  </div>
                  <div class="tabs-ul-li-cen-flex">
                    <div>{{ item.age }}岁</div>
                    <div class="tabs-ul-li-cen-flex-b">年龄</div>
                  </div>
                  <!-- <div class="tabs-ul-li-cen-flex">
                    <div style="color: #ff9354">{{ item.source_text }}</div>
                    <div class="tabs-ul-li-cen-flex-b">病例来源</div>
                  </div>  --> 
                </div>
                <!-- 下 -->
                <div class="tabs-ul-li-bottom">
                  <div class="tabs-ul-li-bottom-box" @click="edit(item)">
                    <div class="tabs-ul-li-bottom-box-img">
                      <img src="@/assets/image/my/bian.png" />
                    </div>
                    <div class="tabs-ul-li-bottom-box-name">编辑</div>
                  </div>
                  <div class="tabs-ul-li-bottom-box-gang"></div>
                  <div class="tabs-ul-li-bottom-box" @click="Delete(item)">
                    <div class="tabs-ul-li-bottom-box-img">
                      <img src="@/assets/image/my/shan.png" />
                    </div>
                    <div class="tabs-ul-li-bottom-box-name">删除</div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </van-tab>

        <van-tab title="审核通过">
          <div class="tabs1-con">
            <van-empty
              class="no"
              v-if="dataList.length == 0"
              description="暂无数据"
            />
            <ul v-else class="tabs-ul">
              <li
                class="tabs-ul-li"
                v-for="(item, index) in dataList"
                :key="index"
              >
                <!-- 上 -->
                <div class="tabs-ul-li-top">
                  <div class="tabs-ul-li-top-img">
                    <img src="@/assets/image/my/tuoyuan.png" />
                  </div>
                  <div class="tabs-ul-li-top-name">{{ item.name }}</div>
                  <div class="tabs-ul-li-top-time">{{ item.created_at }}</div>
                </div>
                <!-- 中 -->
                <div class="tabs-ul-li-cen">
                  <div class="tabs-ul-li-cen-flex">
                    <div>{{ item.sex_text }}</div>
                    <div class="tabs-ul-li-cen-flex-b">性别</div>
                  </div>
                  <div class="tabs-ul-li-cen-flex">
                    <div>{{ item.age }}岁</div>
                    <div class="tabs-ul-li-cen-flex-b">年龄</div>
                  </div>
                  <!-- <div class="tabs-ul-li-cen-flex">
                    <div style="color: #ff9354">{{ item.source_text }}</div>
                    <div class="tabs-ul-li-cen-flex-b">病例来源</div>
                  </div> -->
                </div>
                <!-- 下 -->
                <div class="tabs-ul-li-bottom">
                  <div class="tabs-ul-li-bottom-box" @click="details(item)">
                    <div class="tabs-ul-li-bottom-box-img">
                      <img src="@/assets/image/my/xiang.png" />
                    </div>
                    <div class="tabs-ul-li-bottom-box-name">详情</div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </van-tab>

        <van-tab title="审核驳回">
          <div class="tabs1-con">
            <van-empty
              class="no"
              v-if="dataList.length == 0"
              description="暂无数据"
            />

            <ul v-else class="tabs-ul">
              <li
                class="tabs-ul-li"
                v-for="(item, index) in dataList"
                :key="index"
              >
                <!-- 上 -->
                <div class="tabs-ul-li-top">
                  <div class="tabs-ul-li-top-img">
                    <img src="@/assets/image/my/tuoyuan.png" />
                  </div>
                  <div class="tabs-ul-li-top-name">{{ item.name }}</div>
                  <div class="tabs-ul-li-top-time">{{ item.created_at }}</div>
                </div>
                <!-- 中 -->
                <div class="tabs-ul-li-cen">
                  <div class="tabs-ul-li-cen-flex">
                    <div>{{ item.sex_text }}</div>
                    <div class="tabs-ul-li-cen-flex-b">性别</div>
                  </div>
                  <div class="tabs-ul-li-cen-flex">
                    <div>{{ item.age }}岁</div>
                    <div class="tabs-ul-li-cen-flex-b">年龄</div>
                  </div>
                  <!-- <div class="tabs-ul-li-cen-flex">
                    <div style="color: #ff9354">{{ item.source_text }}</div>
                    <div class="tabs-ul-li-cen-flex-b">病例来源</div>
                  </div> --> 
                </div>
                <!-- 下 -->
                <div class="tabs-ul-li-bottom">
                  <div class="tabs-ul-li-bottom-box" @click="edit(item)">
                    <div class="tabs-ul-li-bottom-box-img">
                      <img src="@/assets/image/my/bian.png" />
                    </div>
                    <div class="tabs-ul-li-bottom-box-name">编辑</div>
                  </div>
                  <div class="tabs-ul-li-bottom-box-gang"></div>
                  <div class="tabs-ul-li-bottom-box" @click="opinion(item)">
                    <div class="tabs-ul-li-bottom-box-img">
                      <img src="@/assets/image/my/yuan.png" />
                    </div>
                    <div class="tabs-ul-li-bottom-box-name">原因</div>
                  </div>
                  <div class="tabs-ul-li-bottom-box-gang"></div>
                  <div class="tabs-ul-li-bottom-box" @click="Delete(item)">
                    <div class="tabs-ul-li-bottom-box-img">
                      <img src="@/assets/image/my/shan.png" />
                    </div>
                    <div class="tabs-ul-li-bottom-box-name">删除</div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: "myCase",
  data() {
    return {
      active: 0,
      limit: 99999,
      page: 1,
      dataList: [],
      loading: false,
      finished: false,
      information: {},
      delIndex: 0,
    };
  },
  activated() {
    this.information = JSON.parse(localStorage.getItem("information")); //localStorage.getItem("information" );
    this.getmyMedicalListApi();
  },
  mounted() {
    this.information = JSON.parse(localStorage.getItem("information")); //localStorage.getItem("information" );
    // this.getmyMedicalListApi();
  },
  methods: {
    //获取列表
    async getmyMedicalListApi() {
      const loading = this.$loading({
        lock: true,
        text: "加载中请稍等...",
        background: "rgba(0,0,0,0.3)",
      });
      const { data: data } = await this.$http.post("auth/myMedical", {
        page: this.page,
        limit: this.limit,
        status: this.active,
      });
      setTimeout(() => {
        this.dataList = data.data.list;
        loading.close();
      }, 700);
    },
    //切换
    onClick(name, title) {
      this.active = name;
      this.delIndex = name;
      this.getmyMedicalListApi();
    },
    //编辑
    edit(item) {
      this.$router.push({
        path: "/questionnaire",
        query: { id: item.id },
      });
    },
    //意见
    async opinion(item) {
      const { data: data } = await this.$http.post("auth/getReason", {
        id: item.id,
      });
      this.$dialog.alert({
        title: "审核意见",
        message: data.data.review_comments,
      });
    },
    //详情
    details(item) {
      this.$router.push({
        path: "/questionnaireDel",
        query: { id: item.id, del: this.delIndex },
      });
    },
    //删除
    Delete(item) {
      this.$dialog
        .confirm({
          title: "确定要删除该条病例吗？",
        })
        .then(async () => {
          const { data: data } = await this.$http.post("auth/delMedical", {
            id: item.id,
          });
          this.$toast(data.message);
          this.getmyMedicalListApi();
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="less" scoped>
// @media screen and (min-width: 270px) {
//   .operation-item-name {
//     font-size: 12px;
//     transform: scale(0.7);
//   }
// }
// @media screen and (max-width: 375px) {
//   .operation-item-name {
//     font-size: 14px;
//   }
// }

.my {
  position: relative;
  margin-bottom: 50px;
  /deep/.van-cell::after {
    border: 0 !important;
  }

  /deep/ .van-cell {
    padding: 0 !important;
  }
  .head {
    width: 108%;
    position: absolute;
    top: -20px;
    left: -4%;
  }
  .tabs {
    position: relative;
    top: 20px;
    left: 0;

    // z-index: 9999;
    /deep/ .van-tab--active {
      color: #fd4734;
      font-weight: 500;
    }
    /deep/ .van-tabs__line {
      z-index: 0;
    }
    .tabs1-con {
      margin-bottom: 20px;
      .recommended-ul {
        position: relative;
        margin-top: 15px;
        .recommended-li {
          position: relative;
          padding: 17px 13px 17px 26px;
          background-color: #fff;
          border-radius: 4px;
          box-shadow: 0px 3px 8px 1px #d7e9eb;
          margin-top: 14px;
          margin-bottom: 14px;
          .recommended-li-l {
            position: absolute;
            top: 0;
            left: 0;
            width: 8px;
            height: 57px;
            background-color: #04b5c4;
          }
          .recommended-li-h {
            position: absolute;
            top: 7px;
            left: 6px;
            width: 8px;
            height: 30px;
            background-color: #ffead1;
          }
          .recommended-li-text {
            min-height: 41px;
            color: #393939;
            font-size: 13px;
            margin-bottom: 14px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            line-height: 1.6;
          }
          .recommended-li-name {
            font-size: 13px;
            color: #fd4734;
          }
        }
        .recommended-li:last-child {
          margin-bottom: 18px;
        }
      }
      .tong {
        width: 60px !important;
      }
      .bo {
        width: 200px !important;
      }
      .operation {
        position: absolute;
        bottom: 0;
        right: 1px;
        background-color: #f8f8f8;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 28px;
        border-radius: 4px 0px 4px 0px;

        .item-r {
          margin-right: 3px;
        }
        .item-l {
          margin-left: 3px;
        }
        .operation-item {
          display: flex;
          align-items: center;
          font-size: 10px;
          color: #333333;
          &:nth-child(1) {
            margin-right: 6px;
          }
          &:nth-child(2) {
            margin-left: 6px;
          }

          .operation-item-name {
            margin-left: 3px;
            font-size: 12px;
            transform: scale(0.9);
          }
          .operation-item-icon {
            margin-top: 2px;
          }
          .item-icon-b {
            color: #04b5c4;
          }
          .item-icon-s {
            color: #df1e1e;
          }
          .item-icon-y {
            color: #4778ff;
          }
        }
      }
    }
  }
  .no {
    margin-top: 90px;
  }
}
.tabs-ul {
  position: relative;
  background-color: #f4f5fb;
}
.tabs-ul-li {
  position: relative;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 10px 16px;
  margin-top: 11px;
  box-shadow: 0px 3px 8px 1px #d7e9eb;
}
.tabs-ul-li-top {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.tabs-ul-li-top-img {
  position: absolute;
  top: -6px;
  left: -15px;
  width: 46px;
  height: 13px;
}
.tabs-ul-li-top-name {
  position: relative;
  font-size: 15px;
  color: #000000;
  font-weight: 600;
  z-index: 1;
  &::after {
    position: absolute;
    bottom: 0;
    right: -5px;
    content: "";
    // width: 33px;
    width: 100%;
    height: 4px;
    background-color: #fbac70;
    z-index: -1;
  }
}
.tabs-ul-li-top-time {
  color: #2476e6;
  background-color: #e8efff;
  padding: 5px 8px;
  border-radius: 1px;
}
.tabs-ul-li-cen {
  background-color: #f5f9fc;
  border-radius: 2px;
  display: flex;
  align-items: center;
  padding: 12px 14px;
  margin-bottom: 16px;
}
.tabs-ul-li-cen-flex {
  flex: 1;
  align-items: center; 
  text-align: center;
}
.tabs-ul-li-cen-flex-b {
  margin-top: 8px;
  color: #909db7;
}
.tabs-ul-li-bottom {
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 13px;
}
.tabs-ul-li-bottom-box {
  display: flex;
  align-items: center;
}
.tabs-ul-li-bottom-box-img {
  width: 13px;
  height: 13px;
  margin-right: 3px;
}
.tabs-ul-li-bottom-box-name {
  color: #2476e6;
}
.tabs-ul-li-bottom-box-gang {
  width: 1px;
  height: 19px;
  background-color: #e8edf8;

  margin: 0 11px;
}
</style>
